import { decorate, observable, action, computed } from "mobx";
import { toast } from "react-toastify";
import { request } from "../utils";

class ContractsStore {
  loading = false;
  rawContracts = [];

  get contracts() {
    return this.rawContracts || [];
  }

  async fetchContract(tenantId, forceRefresh) {
    const cachedTenantContract = this.rawContracts.find(c => c?.current?.tenantId === tenantId);
    if (cachedTenantContract && !forceRefresh) return cachedTenantContract;
    this.loading = true;
    try {
      let tenantContract = await request.get(`/v1/contracts?tenantId=${tenantId}`);
      if (Object.keys(tenantContract).length === 0) tenantContract = { current: { tenantId } };
      this.rawContracts = this.rawContracts.concat(tenantContract);
      this.loading = false;
      return tenantContract;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async addContract(tenantId, contractParams) {
    try {
      this.loading = true;
      const newContract = await request.post(`/v1/contracts?tenantId=${tenantId}`, { body: contractParams });
      this.rawContracts = this.rawContracts.map(c => {
        if (c.current.tenantId === tenantId) return newContract;
        return c;
      });
      toast("New contract successfully added");
      this.loading = false;
    } catch (e) {
      console.log("Error adding new contract ", e);
      toast("Error adding new contract");
      this.loading = false;
    }
  }

  async clear() {
    this.rawContracts = [];
  }
}

const DecoratedContractsStore = decorate(ContractsStore, {
  loading: observable,
  rawContracts: observable,
  contracts: computed,
  fetchContract: action,
  addContract: action,
  clear: action
});

export default new DecoratedContractsStore();
