import React, { useState } from "react";
import { observer } from "mobx-react";
import useOnEnterPressed from "../../hooks/useOnEnterPressed";
import AuthStore from "../../stores/AuthStore";
import Spinner from "../../components/Spinner";
import "./SetupPassword.scss";

function SetupPassword() {
  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [confirmPassword, setConfirmPassword] = useState("");
  const updateConfirmPassword = ({ target }) => setConfirmPassword(target.value);

  const submit = async () => {
    if (password && password === confirmPassword) {
      try {
        await AuthStore.setupPassword(password);
      } catch (err) {
        console.warn(err);
      }
    }
  };
  useOnEnterPressed(submit);

  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};
  const buttonContents = AuthStore.loading ? <Spinner /> : "SET PASSWORD";

  return (
    <div className="setup-password">
      <div className="message">
        Looks like this is your first time! Enter and confirm a new password and we'll get you going.
      </div>
      <div className="inputs-container">
        <input
          className="auth-input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={updatePassword}
        />
        <input
          className="auth-input"
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={updateConfirmPassword}
        />
        <div className="login-button" style={buttonStyles} onClick={submit}>
          {buttonContents}
        </div>
      </div>
    </div>
  );
}

export default observer(SetupPassword);
