import React from "react";
import { observer } from "mobx-react";
import "./Analytics.scss";
import { toJS } from "mobx";
import AuthStore from "../../stores/AuthStore";

function Analytics() {
  console.log(toJS(AuthStore.user, { recurseEverything: true }));
  return (
    <div className="analytics">
      <div className="analytics-header">
        <div className="analytics-header-title">Analytics</div>
        {/* <div className="analytics-header-action" onClick={addTenant}>
          <PlusIcon className="action-label-icon" />
          <span>Add Tenant</span>
        </div> */}
      </div>
      <div className="analytics-body"></div>
    </div>
  );
}

export default observer(Analytics);
