import React from "react";

export default function EditIcon({ className = "putty-edit-icon", strokeWidth = 2, stroke = "#fff" }) {
  return (
    <svg className={className} viewBox="0 0 24 24" strokeWidth={strokeWidth} stroke={stroke} fill="none">
      <path d="M12 20h9" />
      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
    </svg>
  );
}
