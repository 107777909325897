import React from "react";
import "./Spinner.scss";

export default ({ alt, secondary }) => (
  <div className={`lds-ellipsis${alt ? " alt" : ""}${secondary ? " secondary" : ""}`}>
    <div />
    <div />
    <div />
    <div />
  </div>
);
