import React from "react";
import { observer } from "mobx-react";
import { LocationProvider, Router, Redirect } from "@reach/router";
import { ToastContainer } from "react-toastify";
import AuthStore from "../stores/AuthStore";
import Loader from "../components/Loader";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Dashboard from "../components/@Dashboard";
import AddTenant from "../components/@AddTenant";
import TenantDetails from "../components/@TenantDetails";
import Analytics from "../components/@Analytics";
import Auth from "../components/@Auth";
import SetupPassword from "../components/@SetupPassword";
import ResetPassword from "../components/@ResetPassword/ResetPassword";
import ResetPasswordSuccess from "../components/@ResetPasswordSuccess/ResetPasswordSuccess";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

function App() {
  const toastContainer = <ToastContainer position="bottom-left" />;

  if (!AuthStore.authDetermined) {
    return (
      <div className="root loading">
        <Loader />
      </div>
    );
  }

  if (AuthStore.authenticated) {
    return (
      <div className="root">
        <Header />
        <div className="main-content">
          <Sidebar />
          <Router>
            <Dashboard path="/" />
            <AddTenant path="/tenant/new" />
            <TenantDetails path="/tenant/:tenantId" />
            <Analytics path="/analytics" />
            <Redirect from="*" to="/" noThrow />
          </Router>
        </div>
        {toastContainer}
      </div>
    );
  }

  const setupRoute = AuthStore.challengeUser ? <SetupPassword path="/setup" /> : null;
  return (
    <div className="root">
      <Router>
        <Auth path="/" />
        {setupRoute}
        <ResetPassword path="/resetpassword" />
        <ResetPasswordSuccess path="/resetpassword/success" />
        <Redirect from="*" to="/" noThrow />
      </Router>
      {toastContainer}
    </div>
  );
}

const ObservingApp = observer(App);

function ProvidersWrappedApp() {
  return (
    <LocationProvider>
      <ObservingApp />
    </LocationProvider>
  );
}

export default ProvidersWrappedApp;
