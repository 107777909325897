import { useEffect } from "react";

export default function useOnEnterPressed(handler) {
  const onKeyUp = e => {
    if ((e.key === "Enter" || e.keycode === 13) && typeof handler === "function") {
      handler();
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", onKeyUp);
    return () => window.removeEventListener("keyup", onKeyUp);
    // not very efficient if the handler has bound arguments...
  }, [handler]); // eslint-disable-line
}
