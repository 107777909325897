import React from "react";
import CheckIcon from "../../icons/CheckIcon";
import "./RadioOption.scss";

const RadioOption = ({ close, value, selected, select }) => {
  const selectedIcon = selected ? <CheckIcon /> : null;

  return (
    <div className="nickel-radio-option" onClick={select}>
      <div className={`nickel-radio-option-box${close ? " close" : ""}${selected ? " selected" : ""}`}>
        {selectedIcon}
      </div>
      <div className="nickel-radio-option-label">{value}</div>
    </div>
  );
};

export default RadioOption;
