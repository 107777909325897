import React, { useState } from "react";
import { observer } from "mobx-react";
import useTenant from "../../hooks/useTenant";
import useTenantContract from "../../hooks/useTenantContract";
import EditIcon from "../-common/icons/EditIcon";
import TenantsStore from "../../stores/TenantsStore";
import UpdateTenantBasicsModal from "./UpdateTenantBasicsModal";
import UpdateTenantLegalModal from "./UpdateTenantLegalModal";
import UpdateTenantEstimatesModal from "./UpdateTenantEstimatesModal";
import UpdatePaymentProcessorModal from "./UpdatePaymentProcessorModal";
import UpdateContractsModal from "./UpdateContractsModal";
import format from "date-fns/format";
import "./TenantDetails.scss";

function TenantDetails({ tenantId }) {
  const tenant = useTenant(tenantId);
  const tenantContract = useTenantContract(tenantId);
  const { theme, name, abbreviation, subdomain, adminConfig } = tenant || {};
  const { website, timezone, zift, legal, clubName, clubEmail, clubPhone } = adminConfig || {};
  const { merchantId, commissionAccountId, baseAccountId, estimates } = zift || {};
  const { annualDirectDebitVolume, annualCardsVolume, avgDirectDebitTransactionAmount, avgCardsTransactionAmount } =
    estimates || {};
  const { ownershipStructure } = legal || {};
  const { current = {} } = tenantContract;
  const {
    activeDate = null,
    deactiveDate = null,
    nickelCommissionRate = 0,
    nickelEffectiveCommissionRate = 0,
    paymentProcessingRate = 0,
    annualPlatformFee = 0,
    otherRevenue = 0
  } = current;

  const [updateBasicsModalOpen, setUpdateBasicsModalOpen] = useState(false);
  const openUpdateBasicsModal = () => setUpdateBasicsModalOpen(true);
  const closeUpdateBasicsModal = () => setUpdateBasicsModalOpen(false);

  const [updateLegalModalOpen, setUpdateLegalModalOpen] = useState(false);
  const openUpdateLegalModal = () => setUpdateLegalModalOpen(true);
  const closeUpdateLegalModal = () => setUpdateLegalModalOpen(false);

  const [updatePaymentProcessorModalOpen, setUpdatePaymentProcessorModalOpen] = useState(false);
  const openUpdatePaymentProcessorModal = () => setUpdatePaymentProcessorModalOpen(true);
  const closeUpdatePaymentProcessorModal = () => setUpdatePaymentProcessorModalOpen(false);

  const [updateContractsModalOpen, setUpdateContractsModalOpen] = useState(false);
  const openUpdateContractsModal = () => setUpdateContractsModalOpen(true);
  const closeUpdateContractsModal = () => setUpdateContractsModalOpen(false);

  const [updateEstimatesModalOpen, setUpdateEstimatesModalOpen] = useState(false);
  const openUpdateEstimatesModal = () => setUpdateEstimatesModalOpen(true);
  const closeUpdateEstimatesModal = () => setUpdateEstimatesModalOpen(false);

  const deleteTenant = () => {
    TenantsStore.deleteTenant(tenantId);
  };

  const formatPercentage = value => {
    return `${(value * 100).toFixed(2)}%`;
  };

  return (
    <>
      <div className="tenant-details">
        <div className="tenant-header">
          <div className="tenant-picture">
            <img className="tenant-image" src={theme?.logo} alt="Consultant profile" />
          </div>
          <div className="tenant-name">{name}</div>
        </div>
        <div className="tenant-section">
          <div className="tenant-section-title">Basics</div>
          <div className="section-item">
            <div className="section-item-label">Name</div>
            <div className="section-item-value">{name}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Abbreviation</div>
            <div className="section-item-value">{abbreviation}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Website</div>
            <div className="section-item-value">{website}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Instance</div>
            <div className="section-item-value">{subdomain}.nickeldxm.com</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Timezone</div>
            <div className="section-item-value">{timezone}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Club name</div>
            <div className="section-item-value">{clubName}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Club email</div>
            <div className="section-item-value">{clubEmail}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Club phone</div>
            <div className="section-item-value">{clubPhone}</div>
          </div>
          <div className="edit-button" onClick={openUpdateBasicsModal}>
            <EditIcon className="edit-button-icon" />
            <span>Edit</span>
          </div>
        </div>
        <div className="tenant-section">
          <div className="tenant-section-title">Legal</div>
          <div className="section-item">
            <div className="section-item-label">Ownership Structure</div>
            <div className="section-item-value">{ownershipStructure}</div>
          </div>
          <div className="edit-button" onClick={openUpdateLegalModal}>
            <EditIcon className="edit-button-icon" />
            <span>Edit</span>
          </div>
        </div>
        <div className="tenant-section">
          <div className="tenant-section-title">Payment Processor</div>
          <div className="section-item">
            <div className="section-item-label">Zift Merchant Id</div>
            <div className="section-item-value">{merchantId}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Zift Base Account Id</div>
            <div className="section-item-value">{baseAccountId}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Zift Commission Account Id</div>
            <div className="section-item-value">{commissionAccountId}</div>
          </div>
          <div className="edit-button" onClick={openUpdatePaymentProcessorModal}>
            <EditIcon className="edit-button-icon" />
            <span>Edit</span>
          </div>
        </div>
        <div className="tenant-section">
          <div className="tenant-section-title">Contracts</div>
          <div className="section-item">
            <div className="section-item-label">Active Date</div>
            <div className="section-item-value">{activeDate ? format(new Date(activeDate), "MMM do, yyyy") : ""}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Deactive Date</div>
            <div className="section-item-value">{deactiveDate ? deactiveDate : "none"}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Nickel Commission Rate</div>
            <div className="section-item-value">{formatPercentage(nickelCommissionRate)}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Includes Processing Fee?</div>
            <div className="section-item-value">
              {nickelCommissionRate === nickelEffectiveCommissionRate ? "Yes" : "No"}
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Payment Processing Fee</div>
            <div className="section-item-value">{formatPercentage(paymentProcessingRate)}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Annual Platform Fee</div>
            <div className="section-item-value">{annualPlatformFee}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Other Revenue</div>
            <div className="section-item-value">{otherRevenue}</div>
          </div>
          <div className="edit-button" onClick={openUpdateContractsModal}>
            <EditIcon className="edit-button-icon" />
            <span>Edit</span>
          </div>
        </div>
        <div className="tenant-section">
          <div className="tenant-section-title">Estimates</div>
          <div className="section-item">
            <div className="section-item-label">Annual Direct Debit Volume</div>
            <div className="section-item-value">${annualDirectDebitVolume}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Annual Cards Volume</div>
            <div className="section-item-value">${annualCardsVolume}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Avg. Direct Debit Transaction</div>
            <div className="section-item-value">${avgDirectDebitTransactionAmount}</div>
          </div>
          <div className="section-item">
            <div className="section-item-label">Avg. Card Transaction</div>
            <div className="section-item-value">${avgCardsTransactionAmount}</div>
          </div>
          <div className="edit-button" onClick={openUpdateEstimatesModal}>
            <EditIcon className="edit-button-icon" />
            <span>Edit</span>
          </div>
        </div>
        <div className="tenant-section">
          <div className="section-item">
            <div className="button" onClick={deleteTenant}>
              Delete Tenant
            </div>
          </div>
        </div>
      </div>
      <UpdateTenantBasicsModal isOpen={updateBasicsModalOpen} close={closeUpdateBasicsModal} tenant={tenant} />
      <UpdateTenantLegalModal isOpen={updateLegalModalOpen} close={closeUpdateLegalModal} tenant={tenant} />
      <UpdatePaymentProcessorModal
        isOpen={updatePaymentProcessorModalOpen}
        close={closeUpdatePaymentProcessorModal}
        tenant={tenant}
      />
      <UpdateContractsModal
        isOpen={updateContractsModalOpen}
        close={closeUpdateContractsModal}
        tenant={tenant}
        tenantContract={tenantContract}
      />
      <UpdateTenantEstimatesModal isOpen={updateEstimatesModalOpen} close={closeUpdateEstimatesModal} tenant={tenant} />
    </>
  );
}

export default observer(TenantDetails);
