import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { getDataURLFromFile } from "../../utils";
import TenantsStore from "../../stores/TenantsStore";
import "./AddTenant.scss";

const cancel = () => navigate("/");

function AddTenant() {
  const logoUploadRef = useRef();
  const emblemUploadRef = useRef();

  const [name, setName] = useState("");
  const updateName = ({ target }) => setName(target.value);

  const [subdomain, setSubdomain] = useState("");
  const updateSubdomain = ({ target }) => setSubdomain(target.value);

  const [abbreviation, setAbbreviation] = useState("");
  const updateAbbreviation = ({ target }) => setAbbreviation(target.value);

  const [primaryColor, setPrimaryColor] = useState("");
  const updatePrimaryColor = ({ target }) => setPrimaryColor(target.value);

  const [secondaryColor, setSecondaryColor] = useState("");
  const updateSecondaryColor = ({ target }) => setSecondaryColor(target.value);

  const [logo, setLogo] = useState();
  const [logoPreview, setLogoPreview] = useState();
  const selectLogo = () => logoUploadRef.current.click();
  const handleLogoSelected = async ({ target }) => {
    if (target.files && target.files[0]) {
      setLogo(target.files[0]);
      const logoDataURL = await getDataURLFromFile(target.files[0]);
      setLogoPreview(logoDataURL);
    }
  };

  const [emblem, setEmblem] = useState();
  const [emblemPreview, setEmblemPreview] = useState();
  const selectEmblem = () => emblemUploadRef.current.click();
  const handleEmblemSelected = async ({ target }) => {
    if (target.files && target.files[0]) {
      setEmblem(target.files[0]);
      const emblemDataURL = await getDataURLFromFile(target.files[0]);
      setEmblemPreview(emblemDataURL);
    }
  };

  const [userName, setUserName] = useState("");
  const updateUserName = ({ target }) => setUserName(target.value);

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const save = async () => {
    TenantsStore.addTenant(
      {
        name,
        subdomain,
        abbreviation,
        theme: {
          primaryColor,
          secondaryColor,
          dxmPrimaryColor: primaryColor,
          dxmSecondaryColor: secondaryColor,
          appPrimaryColor: primaryColor,
          appSecondaryColor: secondaryColor
        },
        initialUser: { name: userName, email }
      },
      logo,
      emblem
    );
    navigate("/");
  };

  const logoPreviewImg = logoPreview ? <img className="logo" src={logoPreview} alt="Logo for branding" /> : null;

  const emblemPreviewImg = emblemPreview ? (
    <img className="logo" src={emblemPreview} alt="Emblem for branding" />
  ) : null;

  return (
    <>
      <div className="add-tenant">
        <div className="page-header">
          <div className="page-title">
            <div className="title-input-label">Tenant Name:</div>
            <input value={name} onChange={updateName} placeholder="ex. Nickel University" />
          </div>
          <div className="action-buttons">
            <div className="button" onClick={cancel}>
              Cancel
            </div>
            <div className="button" onClick={save}>
              Save
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="content-column">
            <div className="content-section">
              <div className="content-section-title">Subdomain:</div>
              <input value={subdomain} onChange={updateSubdomain} placeholder="ex. nickeluniversity" />
            </div>
            <div className="content-section">
              <div className="content-section-title">Abbreviation:</div>
              <input value={abbreviation} onChange={updateAbbreviation} placeholder="ex. NU" />
            </div>
            <div className="content-section">
              <div className="content-section-title">Primary Color:</div>
              <input value={primaryColor} onChange={updatePrimaryColor} placeholder="ex. #fc5a40" />
            </div>
            <div className="content-section">
              <div className="content-section-title">Secondary Color:</div>
              <input value={secondaryColor} onChange={updateSecondaryColor} placeholder="ex. #777777" />
            </div>
            <div className={`content-section logo ${logoPreviewImg ? " top-aligned" : ""}`}>
              <div className="content-section-title">Logo:</div>
              {logoPreviewImg}
              <div className="button" onClick={selectLogo}>
                {logoPreviewImg ? "Change" : "+ Add Logo"}
              </div>
            </div>
            <div className={`content-section logo ${emblemPreviewImg ? " top-aligned" : ""}`}>
              <div className="content-section-title">Emblem:</div>
              {emblemPreviewImg}
              <div className="button" onClick={selectEmblem}>
                {emblemPreviewImg ? "Change" : "+ Add Emblem"}
              </div>
            </div>
          </div>
          <div className="content-column">
            <div className="content-section">
              <div className="content-section-title">Initial User Name:</div>
              <input value={userName} onChange={updateUserName} placeholder="ex. Jane Doe" />
            </div>
            <div className="content-section">
              <div className="content-section-title">Initial User Email:</div>
              <input value={email} onChange={updateEmail} placeholder="ex. jane@doe.com" />
            </div>
          </div>
        </div>
      </div>
      <input
        ref={logoUploadRef}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        className="logo-upload-input"
        onChange={handleLogoSelected}
      />
      <input
        ref={emblemUploadRef}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        className="logo-upload-input"
        onChange={handleEmblemSelected}
      />
    </>
  );
}

export default observer(AddTenant);
