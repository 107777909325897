import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import TenantsStore from "../stores/TenantsStore";

export default function useTenant(tenantId) {
  const { tenants } = TenantsStore;
  const [tenant, setTenant] = useState();
  useEffect(() => {
    const matchingTenant = tenants.find(m => m.tenantId === tenantId);
    if (matchingTenant) {
      setTenant(toJS(matchingTenant, { recurseEverything: true }));
    } else {
      // The useEffect dependence on tenants should update our local tenant when this returns
      if (tenantId) {
        (async () => {
          const tenant = await TenantsStore.fetchTenant(tenantId);
          if (!tenant) navigate("/");
        })();
      }
    }
  }, [tenants, tenantId]);
  return tenant || {};
}
