import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AuthStore from "../../stores/AuthStore";
import "./Header.scss";

function Header() {
  const menuRef = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => menuOpen && setMenuOpen(false);
  useOnClickOutside(menuRef, closeMenu);

  const signOut = () => AuthStore.signOut();

  return (
    <div className="header">
      <Link to="/">
        <svg className="logo" viewBox="0 0.66 26.59 28.765" preserveAspectRatio="xMinYMin meet">
          <polygon
            id="N"
            points="20.15, 0.66 20.15, 19.18 6, 0.66 0, 0.66 0, 29.42 6.43, 29.42 6.43, 11.4 20.15, 29.42 26.59, 29.42 26.59, 0.66"
          />
        </svg>
      </Link>
      <div className={`right-items${AuthStore.authenticated ? " signed-in" : ""}`}>
        <div ref={menuRef} className="profile-dropdown">
          <div className="dropdown-button" onClick={toggleMenu}>
            <div className="profile-info">
              <div className="profile-name">Tanner Bindrup</div>
              <div className="account-type">Admin</div>
            </div>
            <svg className="chevron-down" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--NickelPrimary)" fill="none">
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
          <div className={`dropdown-menu${menuOpen ? " open" : ""}`}>
            <div className="menu-item" onClick={signOut}>
              Sign Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Header);
