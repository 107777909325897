import { decorate, observable, action, computed, autorun } from "mobx";
import { toast } from "react-toastify";
// import { LOGO_HOST } from "../constants";
import { request } from "../utils";
import AuthStore from "./AuthStore";

const acceptableStackStatuses = ["CREATE_COMPLETE", "CREATE_FAILED", "DELETE_COMPLETE", "DELETE_FAILED"];
const acceptableCloudfrontStatuses = ["Deployed"];
const sleep = async (s = 1) => await new Promise(r => setTimeout(() => r(), s * 1000));

class TenantsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.authenticated) this.fetchTenants();
      else this.clear();
    });
  }

  loading = false;

  rawTenants = [];

  rawContracts = [];

  search = "";

  get tenants() {
    return (
      this.rawTenants
        ?.slice()
        ?.sort((a, b) => a.name > b.name)
        ?.filter(tenant => {
          const { name, subdomain } = tenant;
          const searchTerm = this.search?.toLowerCase();
          return name.toLowerCase().includes(searchTerm) || subdomain.toLowerCase().includes(searchTerm);
        }) || []
    );
  }

  async fetchTenants() {
    this.loading = true;
    const tenants = await request.get("/v1/tenants");
    this.rawTenants = tenants;
    this.rawTenants
      .filter(t => ["creating", "deleting"].includes(t.status))
      .forEach(t => this.pollTenantStatus(t.tenantId));
    this.loading = false;
  }

  async addTenant(tenantParams, logo, emblem) {
    toast("Tenant creation started");
    const preTenants = this.rawTenants.slice();
    this.rawTenants = this.rawTenants.concat({ ...tenantParams, tenantId: "new", status: "creating" });
    try {
      const newTenant = await request.post("/v1/tenants", { body: tenantParams });
      this.rawTenants = this.rawTenants.map(t => {
        if (t.tenantId === "new") return { ...newTenant, creating: true };
        return t;
      });

      if (newTenant.tenantId && logo) {
        try {
          const fileData = await request.get(`/v1/tenants/${newTenant.tenantId}/theme/logo/upload`, {
            headers: { "file-type": logo.type }
          });

          const { url } = fileData || {};
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: logo,
            headers: { "Content-Type": logo.type }
          });
          if (!uploadResponse.ok) throw new Error("Upload failed.");
        } catch (err) {
          console.warn("Error uploading logo:", err);
          throw err;
        }

        try {
          const fileData = await request.get(`/v1/tenants/${newTenant.tenantId}/theme/appheaderlogo/upload`, {
            headers: { "file-type": logo.type }
          });

          const { url } = fileData || {};
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: logo,
            headers: { "Content-Type": logo.type }
          });
          if (!uploadResponse.ok) throw new Error("Upload failed.");
        } catch (err) {
          console.warn("Error uploading logo:", err);
          throw err;
        }
      }

      if (newTenant.tenantId && emblem) {
        try {
          const fileData = await request.get(`/v1/tenants/${newTenant.tenantId}/theme/emblem/upload`, {
            headers: { "file-type": emblem.type }
          });

          const { url } = fileData || {};
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: emblem,
            headers: { "Content-Type": emblem.type }
          });
          if (!uploadResponse.ok) throw new Error("Upload failed.");
        } catch (err) {
          console.warn("Error uploading emblem:", err);
          throw err;
        }

        try {
          const fileData = await request.get(`/v1/tenants/${newTenant.tenantId}/theme/appemblem/upload`, {
            headers: { "file-type": emblem.type }
          });

          const { url } = fileData || {};
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: emblem,
            headers: { "Content-Type": emblem.type }
          });
          if (!uploadResponse.ok) throw new Error("Upload failed.");
        } catch (err) {
          console.warn("Error uploading emblem:", err);
          throw err;
        }
      }

      await this.pollTenantStatus(newTenant.tenantId);
    } catch (err) {
      toast("Error adding new tenant");
      this.rawTenants = preTenants;
    }
  }

  async fetchTenant(tenantId, forceRefresh) {
    const cachedTenant = this.rawTenants.find(m => m.tenantId === tenantId);
    if (cachedTenant && !forceRefresh) return cachedTenant;

    this.loading = true;
    try {
      const tenant = await request.get(`/v1/tenants/${tenantId}`);
      if (this.rawTenants.map(m => m.tenantId).includes(tenant.tenantId)) {
        this.rawTenants = this.rawTenants.map(m => {
          if (m.tenantId === tenant.tenantId) return tenant;
          return m;
        });
      } else {
        this.rawTenants = this.rawTenants.concat(tenant);
      }
      this.loading = false;
      return tenant;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async updateTenant(tenantId, updates) {
    this.loading = true;
    try {
      const updatedTenant = await request.put(`/v1/tenants/${tenantId}`, { body: updates });
      this.rawTenants = this.rawTenants.map(t => {
        if (t.tenantId === tenantId) return updatedTenant;
        return t;
      });
      this.loading = false;
      toast("Successfully updated tenant!");
    } catch (e) {
      this.loading = false;
      console.log("Error updating tenant ", e);
    }
  }

  async deleteTenant(tenantId) {
    try {
      toast("Tenant deletion started");
      this.rawTenants = this.rawTenants.map(t => ({ ...t, status: t.tenantId === tenantId ? "deleting" : t.status }));
      const deletingTenant = await request.post(`/v1/tenants/${tenantId}/delete`);
      this.rawTenants = this.rawTenants.map(t => {
        if (t.tenantId === tenantId) return deletingTenant;
        return t;
      });
      await this.pollTenantStatus(tenantId);
    } catch (err) {
      toast("Error deleting tenant");
      console.warn(err);
    }
  }

  async disableTenant(tenantId) {
    const preTenants = this.rawTenants.slice();
    this.rawTenants = this.rawTenants.map(t => {
      if (t.tenantId === tenantId) return { ...t, disabled: true };
      return t;
    });
    try {
      const disabledTenant = await request.post(`/v1/tenants/${tenantId}/disable`);
      this.rawTenants = this.rawTenants.map(t => {
        if (t.tenantId === tenantId) return disabledTenant;
        return t;
      });
    } catch (err) {
      console.warn(err);
      this.rawTenants = preTenants;
    }
  }

  async enableTenant(tenantId) {
    const preTenants = this.rawTenants.slice();
    this.rawTenants = this.rawTenants.map(t => {
      if (t.tenantId === tenantId) return { ...t, disabled: false };
      return t;
    });
    try {
      const enabledTenant = await request.post(`/v1/tenants/${tenantId}/enable`);
      this.rawTenants = this.rawTenants.map(t => {
        if (t.tenantId === tenantId) return enabledTenant;
        return t;
      });
    } catch (err) {
      console.warn(err);
      this.rawTenants = preTenants;
    }
  }

  async pollTenantStatus(tenantId) {
    let status;
    let cfStatus;
    let errorCount = 0;
    while (
      !acceptableStackStatuses.includes(status) &&
      !acceptableCloudfrontStatuses.includes(cfStatus) &&
      errorCount < 3
    ) {
      try {
        const { stackStatus, cloudfrontStatus } = await request.get(`/v1/tenants/${tenantId}/status`);
        status = stackStatus;
        cfStatus = cloudfrontStatus;
        if (acceptableStackStatuses.includes(status) && acceptableCloudfrontStatuses.includes(cfStatus)) break;
      } catch (err) {
        console.warn(err);
        errorCount += 1;
      }

      await sleep(10);
    }

    if (status === "CREATE_COMPLETE" && cfStatus === "Deployed") {
      toast("Tenant creation finished");
      this.fetchTenant(tenantId, true);
    } else if (status === "CREATE_FAILED") {
      throw new Error("Tenant stack didn't deploy successfully");
    } else if (status === "DELETE_COMPLETE" && cfStatus === "Deployed") {
      toast("Tenant deletion finished");
      this.rawTenants = this.rawTenants.filter(t => t.tenantId !== tenantId);
    } else if (status === "DELETE_FAILED") {
      throw new Error("Tenant stack didn't delete successfully");
    } else {
      throw new Error("Something went wrong with tenant stack updates.");
    }

    return status;
  }

  setSearch(searchText) {
    this.search = searchText;
  }

  async clear() {
    this.rawTenants = [];
  }
}

const DecoratedTenantsStore = decorate(TenantsStore, {
  loading: observable,
  rawTenants: observable,
  search: observable,
  tenants: computed,
  fetchTenants: action,
  addTenant: action,
  fetchTenant: action,
  updateTenant: action,
  deleteTenant: action,
  pollTenantStatus: action,

  clear: action
});

export default new DecoratedTenantsStore();
