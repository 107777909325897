import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../hooks/usePortal";
import TenantsStore from "../../../stores/TenantsStore";
import FullScreenModal from "../../-common/FullScreenModal";
import Spinner from "../../-common/Spinner";
import Input from "../../-common/Input";
import "./UpdatePaymentProcessorModal.scss";

function UpdatePaymentProcessorModal({ close, tenant }) {
  const { adminConfig } = tenant || {};
  const { zift } = adminConfig || {};
  const { commissionAccountId: currentCommissionAccountId, baseAccountId, merchantId } = zift || {};

  const [commissionAccountId, setCommissionAccountId] = useState(currentCommissionAccountId);
  const updateCommissionAccountId = ({ target }) => setCommissionAccountId(target?.value);

  useEffect(() => {
    setCommissionAccountId(currentCommissionAccountId);
  }, [currentCommissionAccountId]);

  const save = async () => {
    await TenantsStore.updateTenant(tenant?.tenantId, {
      adminConfig: {
        ...(tenant?.adminConfig || {}),
        zift: { ...(tenant?.adminConfig?.zift || {}), baseAccountId, commissionAccountId, merchantId }
      }
    });
    close();
  };

  const saveable = commissionAccountId && commissionAccountId !== currentCommissionAccountId;
  const saveButtonStyles = saveable
    ? TenantsStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const saveButtonContent = false ? <Spinner /> : "SAVE";
  const cancelButtonStyles = false ? { opacity: 0.1, pointerEvents: "none" } : {};

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="update-tenant-estimates-modal">
        <div className="modal-contents">
          <div className="headline">Payment Processor</div>
          <div className="content">
            <div className="info-col">
              <div className="info-container">
                <div className="info-labels">
                  <div className="info-label">Merchant Id</div>
                  <div className="info-label">Base Account Id</div>
                  <div className="info-label editable">Commission Account Id</div>
                </div>
                <div className="info-values">
                  <div className="info-value">{merchantId}</div>
                  <div className="info-value">{baseAccountId}</div>
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Abbreviation"
                      value={commissionAccountId}
                      onChange={updateCommissionAccountId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <div className="modal-button" style={saveButtonStyles} onClick={save}>
            {saveButtonContent}
          </div>
          <div className="modal-button cancel" style={cancelButtonStyles} onClick={close}>
            CANCEL
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdatePaymentProcessorModal);

const UpdatePaymentProcessorModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdatePaymentProcessorModalContainer;
