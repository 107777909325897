import React from "react";
import "./ResetPasswordSuccess.scss";

function ResetPasswordSuccess() {
  return (
    <div className="reset-password-success">
      <div className="message">
        Success! Hang tight - we're just logging you in. You'll use your new password from here on out.
      </div>
    </div>
  );
}

export default ResetPasswordSuccess;
