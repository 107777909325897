import React from "react";
import { observer } from "mobx-react";
import { Link, useLocation } from "@reach/router";
import ChevronLeftIcon from "../-common/icons/ChevronLeftIcon";
import HomeIcon from "../-common/icons/HomeIcon";
import LineIcon from "../-common/icons/LineIcon";
import "./Sidebar.scss";

function Sidebar() {
  const { pathname } = useLocation();

  const back = () => window.history.go(-1);

  if (pathname.split("/").length > 2) {
    return (
      <div className="sidebar detail">
        <div className="links-section">
          <div className="sidebar-link-wrapper">
            <div className={`sidebar-link back`} onClick={back}>
              <ChevronLeftIcon className="link-icon" />
              <span>Back</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="sidebar">
      <div className="links-section">
        <Link className="sidebar-link-wrapper" to="/">
          <div className={`sidebar-link${pathname === "/" ? " active" : ""}`}>
            <HomeIcon className="link-icon" />
            <span>Dashboard</span>
          </div>
        </Link>
      </div>
      <div className="links-section">
        <Link className="sidebar-link-wrapper" to="/analytics">
          <div className={`sidebar-link${pathname === "/analytics" ? " active" : ""}`}>
            <LineIcon className="link-icon line-icon" />
            <span>Analytics</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default observer(Sidebar);
