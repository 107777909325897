import React from "react";
import "./CharacterCounter.scss";

const CharacterCounter = ({ valueLength, maxLength }) => {
  const redLimitStyles =
    valueLength === maxLength ? { color: "var(--Red)" } : null;
  return (
    <div
      className="character-counter"
      style={redLimitStyles}
    >{`${valueLength} / ${maxLength}`}</div>
  );
};
export default CharacterCounter;
