import React from "react";

export default function HomeIcon({ className = "putty-home-icon", strokeWidth = 2, stroke = "#fff" }) {
  return (
    <svg className={className} viewBox="0 0 24 24" strokeWidth={strokeWidth} stroke={stroke} fill="none">
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  );
}
