import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import ContractsStore from "../stores/ContractsStore";

export default function useTenantContract(tenantId) {
  const { contracts } = ContractsStore;
  const [contract, setContract] = useState();
  useEffect(() => {
    const matchingContract = contracts.find(c => c?.current?.tenantId === tenantId);
    if (matchingContract) {
      setContract(toJS(matchingContract, { recurseEverything: true }));
    } else {
      if (tenantId) {
        (async () => {
          const contract = await ContractsStore.fetchContract(tenantId);
          if (!contract) navigate("/");
        })();
      }
    }
  }, [contracts, tenantId]);
  return contract || {};
}
