import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import useOnEnterPressed from "../../hooks/useOnEnterPressed";
import AuthStore from "../../stores/AuthStore";
import Spinner from "../../components/Spinner";
import "./Auth.scss";

function Auth() {
  const [username, setUsername] = useState("");
  const updateUsername = ({ target }) => setUsername(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const logIn = () => AuthStore.logIn(username, password);
  useOnEnterPressed(logIn);

  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};
  const buttonContents = AuthStore.loading ? <Spinner /> : "LOG IN";

  return (
    <div className="auth">
      <div className="inputs-container">
        <div className="auth-square-title">Nickel Admin Portal</div>
        <input className="auth-input" type="email" placeholder="Email" value={username} onChange={updateUsername} />
        <input
          className="auth-input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={updatePassword}
        />
        <div className="login-button" style={buttonStyles} onClick={logIn}>
          {buttonContents}
        </div>
        <div className="link-container">
          Forgot your password?
          <Link to="/resetpassword" className="link">
            Reset It
          </Link>
        </div>
      </div>
    </div>
  );
}

export default observer(Auth);
