
export const ENVIRONMENT_PREFIX = "";
export const API_HOST = "https://api.admin.nickeldxm.com";
export const API_NAME = "prod-nickel-admin";
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  identityPoolId: "us-east-1:56248089-a13e-47a4-bfcd-63def102fbf4",
  region: "us-east-1",
  userPoolId: "us-east-1_0zHEA8ShB",
  userPoolWebClientId: "c2mcs6grq2jaal2u2p708rq3m",
  authenticationFlowType: "USER_PASSWORD_AUTH"
};