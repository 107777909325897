import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import TenantsStore from "../../stores/TenantsStore";
import PlusIcon from "../-common/icons/PlusIcon";
import Input from "../-common/Input";
import Spinner from "../-common/Spinner";
import "./Dashboard.scss";

function Dashboard() {
  const { rawTenants, tenants, loading, search } = TenantsStore || {};

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target.value);
  useEffect(() => {
    if (searchText !== search) {
      TenantsStore.setSearch(searchText);
    }
  }, [search, searchText]);

  const addTenant = () => navigate("/tenant/new");

  const openTenantDetail = tenant => () => navigate(`/tenant/${tenant?.tenantId}`);

  if (!rawTenants?.length && loading) {
    return (
      <div className="dashboard dashboard-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const tenantRows = tenants.map((tenant, i) => {
    const { name, subdomain, users, totalClubMembers } = tenant;
    return (
      <tr key={i}>
        <td>{name}</td>
        <td>{subdomain}</td>
        <td>{users?.length}</td>
        <td>{totalClubMembers}</td>
        <td>
          <div className="action" onClick={openTenantDetail(tenant)}>
            View Details
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="dashboard-header-title">Tenants</div>
        <div className="dashboard-header-action" onClick={addTenant}>
          <PlusIcon className="action-label-icon" />
          <span>Add Tenant</span>
        </div>
      </div>
      <div className="dashboard-body">
        <div className="main-col">
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Subdomain</th>
                <th>Total Users</th>
                <th>Club Members</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{tenantRows}</tbody>
          </table>
        </div>
        <div className="stats-col">
          <div className="group-by">
            <div className="group-by-title">Search</div>
            <div className="group-by-options">
              <Input type="text" placeholder="Search" value={searchText} onChange={updateSearchText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Dashboard);
