import React from "react";
import "./Input.scss";

const Input = ({ type, placeholder, value, onChange, error, disabled }) => {
  const errorMessage = error ? <p className="putty-input-error">{error}</p> : null;

  const errorIcon = error ? (
    <svg className="putty-input-error-icon" viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#E0614A" />
      <line x1="5.5" y1="5.5" x2="10.5" y2="10.5" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="10.5" y1="5.5" x2="5.5" y2="10.5" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ) : null;

  const errorStyles = error
    ? {
        borderRight: "6px solid #E0614A"
      }
    : {};
  return (
    <div className={`putty-input${disabled ? " disabled" : ""}`}>
      <input
        style={errorStyles}
        type={type}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange || (() => null)}
      />
      {errorIcon}
      {errorMessage}
    </div>
  );
};

export default Input;
