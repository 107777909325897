import { decorate, observable, action } from "mobx";
import Amplify, { Auth } from "aws-amplify";
import { navigate } from "@reach/router";
import { COGNITO_CONFIG, API_CONFIG } from "../constants";

class AuthStore {
  constructor() {
    Amplify.configure({ Auth: COGNITO_CONFIG, API: API_CONFIG });
    this.checkForAuthenticatedUser();
  }

  loading = false;
  authDetermined = false;
  authenticated = false;
  user = {};

  async checkForAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.authenticated = true;
        this.user = user;
      } else {
        this.authenticated = false;
        this.user = {};
      }
    } catch (err) {
      // This also means user isn't authenticated
      this.authenticated = false;
    }

    this.authDetermined = true;
  }

  async logIn(username, password) {
    this.loading = true;
    try {
      const authResponse = await Auth.signIn(username || "-", password || "-");

      if (authResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.challengeUser = authResponse;
        navigate(`/setup`);
      } else {
        const user = await Auth.currentAuthenticatedUser();
        this.user = user;
        this.authenticated = true;
      }
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
    }
    this.loading = false;
  }

  challengeUser;

  async setupPassword(newPassword) {
    this.loading = true;
    try {
      const user = await Auth.completeNewPassword(this.challengeUser, newPassword);
      this.challengeUser = null;
      this.user = user;
      this.authenticated = true;
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
    }
    this.loading = false;
  }

  async sendResetPasswordCode(username) {
    let result;

    this.loading = true;
    try {
      await Auth.forgotPassword(username);
      result = true;
    } catch (err) {
      result = false;
    }

    this.loading = false;
    return result;
  }

  async resetPassword(username, code, newPassword) {
    let result;

    this.loading = true;
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      result = true;
    } catch (err) {
      console.warn("Error resetting password:", err);
      result = false;
    }

    this.loading = false;
    return result;
  }

  async logOut() {
    this.loading = true;
    try {
      await Auth.signOut();
      this.user = {};
      this.authenticated = false;
    } catch (err) {
      console.warn(err);
    }
    this.loading = false;
  }
}

const DecoratedAuthStore = decorate(AuthStore, {
  loading: observable,
  authDetermined: observable,
  authenticated: observable,
  user: observable,
  checkForAuthenticatedUser: action,
  logIn: action,
  challengeUser: observable,
  setupPassword: action,
  logOut: action
});

export default new DecoratedAuthStore();
