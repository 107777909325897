import React, { useCallback } from "react";
import { FiX } from "react-icons/fi";
import Label from "../Label";
import CharacterCounter from "../CharacterCounter";
import "./CurrencyInput.scss";

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput = ({
  value, //required
  type, //required
  onChange, //required
  countCharacters = false,
  clearable = false,
  onClear,
  formatter,
  validator,
  icon: Icon,
  label,
  labelStyles,
  inputStyles,
  inputContainerStyles,
  iconStyles,
  maxLength = 65,
  onClick,
  placeholder
}) => {
  const valueLength = (value || "").length || 0;
  const clearStyles = Icon ? { right: 40 } : null;

  const clear = useCallback(() => {
    onChange("");
    onClear();
  }, [onClear, onChange]);

  const labelComponent = label ? (
    <Label style={labelStyles} value={label} />
  ) : null;
  const iconComponent = Icon ? (
    <div className="icon-container" style={iconStyles}>
      <Icon />
    </div>
  ) : null;

  const clearComponent = clearable ? (
    <div className="clearable-container" style={clearStyles} onClick={clear}>
      <FiX />
    </div>
  ) : null;
  const characterCounterComponent = countCharacters ? (
    <div className="character-counter-container">
      <CharacterCounter valueLength={valueLength} maxLength={maxLength} />
    </div>
  ) : null;
  return (
    <div className="currency-input-component-container" onClick={onClick}>
      {labelComponent}
      <div className="currency-input-container" style={inputContainerStyles}>
        <TheInput onChange={onChange} value={value} />
        {clearComponent}
        {iconComponent}
      </div>
      {characterCounterComponent}
    </div>
  );
};

const TheInput = ({
  onChange,
  value,
  className = "",
  max = Number.MAX_SAFE_INTEGER,
  style = {}
}) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error(`invalid value property`);
  }
  const handleKeyDown = useCallback(
    e => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onChange(nextValue);
    },
    [max, onChange, value]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = (value / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });

  return (
    <input
      className={className}
      inputMode="numeric"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      style={style}
      value={valueDisplay}
    />
  );
};

export default CurrencyInput;
