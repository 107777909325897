import axios from "axios";
import { API } from "aws-amplify";
import { API_HOST, API_NAME } from "../constants";

const instance = axios.create({ baseURL: API_HOST });

const makeUnpackedMethod = method => async (...params) => {
  const { data } = await method(...params);
  return data;
};

const makeWrappedMethod = method => async (url, config = {}) => {
  const amplifyMethod = method === "delete" ? "del" : method;
  return await API[amplifyMethod](API_NAME, url, config);
};

const wrapper = {
  bare: {
    post: axios.post,
    get: axios.get,
    put: axios.put,
    delete: axios.delete
  },

  unauthenticated: {
    post: makeUnpackedMethod(instance.post),
    get: makeUnpackedMethod(instance.get),
    put: makeUnpackedMethod(instance.put),
    delete: makeUnpackedMethod(instance.delete)
  },

  post: makeWrappedMethod("post"),
  get: makeWrappedMethod("get"),
  put: makeWrappedMethod("put"),
  delete: makeWrappedMethod("delete")
};

export default wrapper;
