import React from "react";
import "./Label.scss";

const Label = ({ value, style }) => {
  return (
    <div className="label" style={style}>
      {value}
    </div>
  );
};
export default Label;
