import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../hooks/usePortal";
import TenantsStore from "../../../stores/TenantsStore";
import ContractsStore from "../../../stores/ContractsStore";
import FullScreenModal from "../../-common/FullScreenModal";
import Spinner from "../../-common/Spinner";
import Input from "../../-common/Input";
import RadioGroup from "../../-common/RadioGroup";
import CurrencyInput from "../../-common/CurrencyInput";
import format from "date-fns/format";
import "./UpdateContractsModal.scss";

function UpdateContractsModal({ close, tenant, tenantContract }) {
  const { tenantId } = tenant;
  const { current = {} } = tenantContract;
  const {
    contractId = null,
    activeDate = null,
    deactiveDate = null,
    nickelCommissionRate: currentNickelCommissionRate = 0,
    nickelEffectiveCommissionRate: currentNickelEffectiveCommissionRate = 0,
    paymentProcessingRate: currentPaymentProcessingRate = 0,
    annualPlatformFee: currentAnnualPlatformFee = 0,
    otherRevenue: currentOtherRevenue = 0
  } = current;

  const options = ["Yes", "No"];

  const [nickelCommissionRate, setNickelCommissionRate] = useState(currentNickelCommissionRate);
  const updateNickelCommissionRate = ({ target }) => {
    setNickelCommissionRate(target?.value);
    if (includeProcessing === "Yes") setNickelEffectiveCommissionRate(target?.value - paymentProcessingRate);
    else if (includeProcessing === "No") setNickelEffectiveCommissionRate(target?.value);
  };

  const [paymentProcessingRate, setPaymentProcessingRate] = useState(currentPaymentProcessingRate);
  const updatePaymentProcessingRate = ({ target }) => {
    setPaymentProcessingRate(target?.value);
    if (includeProcessing === "Yes") setNickelEffectiveCommissionRate(nickelCommissionRate - target?.value);
  };

  const [nickelEffectiveCommissionRate, setNickelEffectiveCommissionRate] = useState(
    currentNickelEffectiveCommissionRate
  );

  const [includeProcessing, setIncludeProcessing] = useState("Yes");
  const updateIncludesProcessing = value => {
    setIncludeProcessing(value);
    if (value === "Yes") setNickelEffectiveCommissionRate(nickelCommissionRate - paymentProcessingRate);
    else if (value === "No") setNickelEffectiveCommissionRate(nickelCommissionRate);
  };
  const [annualPlatformFee, setAnnualPlatformFee] = useState(currentAnnualPlatformFee);
  const updateAnnualPlatformFee = value => setAnnualPlatformFee(value);

  const [otherRevenue, setOtherRevenue] = useState(currentOtherRevenue);
  const updateOtherRevenue = value => setOtherRevenue(value);

  const [saveable, setSaveable] = useState(false);

  useEffect(() => {
    setSaveable(
      nickelCommissionRate &&
        paymentProcessingRate &&
        (nickelCommissionRate !== (currentNickelCommissionRate * 100).toFixed(2) ||
          paymentProcessingRate !== (currentPaymentProcessingRate * 100).toFixed(2) ||
          annualPlatformFee !== currentAnnualPlatformFee ||
          annualPlatformFee > 0 ||
          otherRevenue !== currentOtherRevenue ||
          annualPlatformFee > 0)
    );
  }, [
    nickelCommissionRate,
    otherRevenue,
    paymentProcessingRate,
    currentPaymentProcessingRate,
    currentNickelCommissionRate,
    annualPlatformFee,
    currentAnnualPlatformFee,
    currentOtherRevenue
  ]);

  useEffect(() => {
    setNickelCommissionRate((currentNickelCommissionRate * 100).toFixed(2));
    setPaymentProcessingRate((currentPaymentProcessingRate * 100).toFixed(2));
    setNickelEffectiveCommissionRate((currentNickelEffectiveCommissionRate * 100).toFixed(2));
    setAnnualPlatformFee(currentAnnualPlatformFee);
    setOtherRevenue(currentOtherRevenue);
  }, [
    currentNickelCommissionRate,
    currentPaymentProcessingRate,
    currentNickelEffectiveCommissionRate,
    currentAnnualPlatformFee,
    currentOtherRevenue
  ]);

  const save = async () => {
    const newContractParams = {
      currentContractId: contractId,
      nickelCommissionRate,
      nickelEffectiveCommissionRate,
      includeProcessing,
      paymentProcessingRate,
      annualPlatformFee,
      otherRevenue
    };
    await ContractsStore.addContract(tenantId, newContractParams);
    close();
  };

  const saveButtonStyles = saveable
    ? TenantsStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const saveButtonContent = false ? <Spinner /> : "SAVE";
  const cancelButtonStyles = false ? { opacity: 0.1, pointerEvents: "none" } : {};

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="update-tenant-estimates-modal">
        <div className="modal-contents">
          <div className="headline">Contracts</div>
          <div className="content">
            <div className="info-col">
              <div className="info-container">
                <div className="info-labels">
                  <div className="info-label">Active Date</div>
                  <div className="info-label">Deactive Date</div>
                  <div className="info-label editable">Nickel Commission Rate</div>
                  <div className="info-label editable">Includes Processing Fee?</div>
                  <div className="info-label editable">Payment Processing Fee</div>
                  <div className="info-label">Nickel Effective Commission Rate</div>
                  <div className="info-label editable">Annual Platform Fee</div>
                  <div className="info-label editable">Other Revenue</div>
                </div>
                <div className="info-values">
                  <div className="info-value">{activeDate ? format(new Date(activeDate), "MMM do, yyyy") : "none"}</div>
                  <div className="info-value">{deactiveDate || "none"}</div>
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Nickel Commission Rate"
                      value={nickelCommissionRate}
                      onChange={updateNickelCommissionRate}
                    />
                  </div>
                  <div className="info-value editable">
                    <RadioGroup options={options} value={includeProcessing} onSelect={updateIncludesProcessing} />
                  </div>
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Payment Processing Rate"
                      value={paymentProcessingRate}
                      onChange={updatePaymentProcessingRate}
                    />
                  </div>
                  <div className="info-value">{nickelEffectiveCommissionRate}%</div>
                  <div className="info-value editable">
                    <CurrencyInput value={annualPlatformFee || 0} onChange={updateAnnualPlatformFee} />
                  </div>
                  <div className="info-value editable">
                    <CurrencyInput value={otherRevenue || 0} onChange={updateOtherRevenue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <div className="modal-button" style={saveButtonStyles} onClick={save}>
            {saveButtonContent}
          </div>
          <div className="modal-button cancel" style={cancelButtonStyles} onClick={close}>
            CANCEL
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdateContractsModal);

const UpdateContractsModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdateContractsModalContainer;
