import React from "react";

export default function PlusIcon({ className = "putty-plus-icon", strokeWidth = 2, stroke = "#fff" }) {
  return (
    <svg className={className} viewBox="0 0 24 24" strokeWidth={strokeWidth} stroke={stroke} fill="none">
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
  );
}
