import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../hooks/usePortal";
import TenantsStore from "../../../stores/TenantsStore";
import FullScreenModal from "../../-common/FullScreenModal";
import Spinner from "../../-common/Spinner";
import Select from "../../-common/Select";
import "./UpdateTenantLegalModal.scss";

const ownershipStructureOptions = ["GA", "SP", "LLC", "C"];

function UpdateTenantLegalModal({ close, tenant }) {
  const { ownershipStructure: currentOwnershipStructure } = tenant?.adminConfig?.legal || {};

  const [ownershipStructure, setOwnershipStructure] = useState(currentOwnershipStructure);
  const updateOwnershipStructure = v => setOwnershipStructure(v);

  useEffect(() => {
    setOwnershipStructure(currentOwnershipStructure);
  }, [currentOwnershipStructure]);

  const save = async () => {
    await TenantsStore.updateTenant(tenant?.tenantId, {
      adminConfig: {
        ...(tenant?.adminConfig || {}),
        legal: { ...(tenant?.adminConfig?.legal || {}), ownershipStructure }
      }
    });
    close();
  };

  const saveable = ownershipStructure && ownershipStructure !== currentOwnershipStructure;
  const saveButtonStyles = saveable
    ? TenantsStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const saveButtonContent = false ? <Spinner /> : "SAVE";
  const cancelButtonStyles = false ? { opacity: 0.1, pointerEvents: "none" } : {};

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="update-tenant-legal-modal">
        <div className="modal-contents">
          <div className="headline">Legal</div>
          <div className="content">
            <div className="info-col">
              <div className="info-container">
                <div className="info-labels">
                  <div className="info-label editable">Ownership Structure</div>
                </div>
                <div className="info-values">
                  <div className="info-value editable">
                    <Select
                      options={ownershipStructureOptions}
                      value={ownershipStructure}
                      onChange={updateOwnershipStructure}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <div className="modal-button" style={saveButtonStyles} onClick={save}>
            {saveButtonContent}
          </div>
          <div className="modal-button cancel" style={cancelButtonStyles} onClick={close}>
            CANCEL
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdateTenantLegalModal);

const UpdateTenantLegalModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdateTenantLegalModalContainer;
