import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../hooks/usePortal";
import TenantsStore from "../../../stores/TenantsStore";
import FullScreenModal from "../../-common/FullScreenModal";
import Spinner from "../../-common/Spinner";
import Input from "../../-common/Input";
import "./UpdateTenantEstimatesModal.scss";

function UpdateTenantEstimatesModal({ close, tenant }) {
  const { estimates: currentEstimates } = tenant?.adminConfig?.zift || {};
  const [estimates, setEstimates] = useState({});
  useEffect(() => {
    setEstimates(currentEstimates || {});
  }, [currentEstimates]);
  const {
    annualDirectDebitVolume,
    annualCardsVolume,
    avgDirectDebitTransactionAmount,
    avgCardsTransactionAmount
  } = estimates;

  const setAnnualDirectDebitVolume = ({ target }) => {
    setEstimates({ ...estimates, annualDirectDebitVolume: target?.value });
  };

  const setAnnualCardsVolume = ({ target }) => {
    setEstimates({ ...estimates, annualCardsVolume: target?.value });
  };

  const setAvgDirectDebitVolume = ({ target }) => {
    setEstimates({ ...estimates, avgDirectDebitTransactionAmount: target?.value });
  };

  const setAvgCardsTransactionAmount = ({ target }) => {
    setEstimates({ ...estimates, avgCardsTransactionAmount: target?.value });
  };

  const save = async () => {
    await TenantsStore.updateTenant(tenant?.tenantId, {
      adminConfig: { ...(tenant?.adminConfig || {}), zift: { ...(tenant?.adminConfig?.zift || {}), estimates } }
    });
    close();
  };

  const saveable =
    annualDirectDebitVolume && annualCardsVolume && avgDirectDebitTransactionAmount && avgCardsTransactionAmount;
  const saveButtonStyles = saveable
    ? TenantsStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const saveButtonContent = false ? <Spinner /> : "SAVE";
  const cancelButtonStyles = false ? { opacity: 0.1, pointerEvents: "none" } : {};

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="update-tenant-estimates-modal">
        <div className="modal-contents">
          <div className="headline">Estimates</div>
          <div className="content">
            <div className="info-col">
              <div className="info-container">
                <div className="info-labels">
                  <div className="info-label editable">Annual Direct Debit Volume</div>
                  <div className="info-label editable">Annual Cards Volume</div>
                  <div className="info-label editable">Avg. Direct Debit Transaction</div>
                  <div className="info-label editable">Avg. Card Transaction</div>
                </div>
                <div className="info-values">
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Annual Direct Debit Volume"
                      value={annualDirectDebitVolume}
                      onChange={setAnnualDirectDebitVolume}
                    />
                  </div>
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Annual Cards Volume"
                      value={annualCardsVolume}
                      onChange={setAnnualCardsVolume}
                    />
                  </div>
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Avg. Direct Debit Transaction"
                      value={avgDirectDebitTransactionAmount}
                      onChange={setAvgDirectDebitVolume}
                    />
                  </div>
                  <div className="info-value editable">
                    <Input
                      type="text"
                      placeholder="Avg. Card Transaction"
                      value={avgCardsTransactionAmount}
                      onChange={setAvgCardsTransactionAmount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <div className="modal-button" style={saveButtonStyles} onClick={save}>
            {saveButtonContent}
          </div>
          <div className="modal-button cancel" style={cancelButtonStyles} onClick={close}>
            CANCEL
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdateTenantEstimatesModal);

const UpdateTenantEstimatesModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdateTenantEstimatesModalContainer;
