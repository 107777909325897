import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { ENVIRONMENT_PREFIX } from "../../../constants";
import usePortal from "../../../hooks/usePortal";
import TenantsStore from "../../../stores/TenantsStore";
import FullScreenModal from "../../-common/FullScreenModal";
import Spinner from "../../-common/Spinner";
import Input from "../../-common/Input";
import Select from "../../-common/Select";
import "./UpdateTenantBasicsModal.scss";

const timezoneOptions = ["EST", "CST", "MST", "PST"];

function UpdateTenantBasicsModal({ close, tenant }) {
  const {
    name: currentName,
    abbreviation: currentAbbreviation,
    clubName: currentClubName,
    clubPhone: currentClubPhone,
    clubEmail: currentClubEmail,
    subdomain,
    adminConfig
  } = tenant || {};
  const { website: currentWebsite, timezone: currentTimezone } = adminConfig || {};

  const [name, setName] = useState(currentName);
  const updateName = ({ target }) => setName(target?.value);

  const [abbreviation, setAbbreviation] = useState(currentAbbreviation);
  const updateAbbreviation = ({ target }) => setAbbreviation(target?.value);

  const [clubName, setClubName] = useState(currentClubName);
  const updateClubName = ({ target }) => setClubName(target?.value);

  const [clubPhone, setClubPhone] = useState(currentClubPhone);
  const updateClubPhone = ({ target }) => setClubPhone(target?.value);

  const [clubEmail, setClubEmail] = useState(currentClubEmail);
  const updateClubEmail = ({ target }) => setClubEmail(target?.value);

  const [website, setWebsite] = useState(currentWebsite);
  const updateWebsite = ({ target }) => setWebsite(target?.value);

  const [timezone, setTimezone] = useState(currentTimezone);
  const updateTimezone = v => setTimezone(v);

  useEffect(() => {
    setName(currentName);
    setAbbreviation(currentAbbreviation);
    setWebsite(currentWebsite);
    setTimezone(currentTimezone);
  }, [currentName, currentAbbreviation, currentWebsite, currentTimezone]);

  const save = async () => {
    await TenantsStore.updateTenant(tenant?.tenantId, {
      name,
      abbreviation,
      adminConfig: { ...(tenant?.adminConfig || {}), website, timezone, clubName, clubEmail, clubPhone }
    });
    close();
  };

  const saveable =
    name &&
    abbreviation &&
    website &&
    timezone &&
    clubName &&
    clubEmail &&
    clubPhone &&
    (name !== currentName ||
      abbreviation !== currentAbbreviation ||
      website !== currentWebsite ||
      timezone !== currentTimezone ||
      clubName !== currentClubName ||
      clubEmail !== currentClubEmail ||
      clubPhone !== currentClubPhone);
  const saveButtonStyles = saveable
    ? TenantsStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const saveButtonContent = false ? <Spinner /> : "SAVE";
  const cancelButtonStyles = false ? { opacity: 0.1, pointerEvents: "none" } : {};

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="update-tenant-estimates-modal">
        <div className="modal-contents">
          <div className="headline">Basics</div>
          <div className="content">
            <div className="info-col">
              <div className="info-container">
                <div className="info-labels">
                  <div className="info-label editable">Name</div>
                  <div className="info-label editable">Abbreviation</div>
                  <div className="info-label editable">Website</div>
                  <div className="info-label">Instance</div>
                  <div className="info-label editable">Timezone</div>
                  <div className="info-label editable">Club Name</div>
                  <div className="info-label editable">Club Email</div>
                  <div className="info-label editable">Club Phone</div>
                </div>
                <div className="info-values">
                  <div className="info-value editable">
                    <Input type="text" placeholder="Name" value={name} onChange={updateName} />
                  </div>
                  <div className="info-value editable">
                    <Input type="text" placeholder="Abbreviation" value={abbreviation} onChange={updateAbbreviation} />
                  </div>
                  <div className="info-value editable">
                    <Input type="text" placeholder="Website" value={website} onChange={updateWebsite} />
                  </div>
                  <div className="info-value">
                    {ENVIRONMENT_PREFIX}
                    {subdomain}.nickeldxm.com
                  </div>
                  <div className="info-value editable">
                    <Select options={timezoneOptions} value={timezone} onChange={updateTimezone} />
                  </div>
                  <div className="info-value editable">
                    <Input type="text" placeholder="Club name" value={clubName} onChange={updateClubName} />
                  </div>
                  <div className="info-value editable">
                    <Input type="text" placeholder="Club email" value={clubEmail} onChange={updateClubEmail} />
                  </div>
                  <div className="info-value editable">
                    <Input type="text" placeholder="Club phone" value={clubPhone} onChange={updateClubPhone} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <div className="modal-button" style={saveButtonStyles} onClick={save}>
            {saveButtonContent}
          </div>
          <div className="modal-button cancel" style={cancelButtonStyles} onClick={close}>
            CANCEL
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdateTenantBasicsModal);

const UpdateTenantBasicsModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdateTenantBasicsModalContainer;
